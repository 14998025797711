import { trigger } from "../../helper/event";
export async function getLanguage() {
    let shopLocal = window.Shopify.locale || document.getElementsByTagName("html")[0].getAttribute("lang") || document.documentElement.lang;
    if(shopLocal && shopLocal != ''){
      try {
        let lanCall = await fetch(`${window.flitsExtensionFeatures.cdnURL}/locales/${window.flitsExtensionObjects.shop_id}/${shopLocal}.json`);
        if (lanCall.ok) {
          let lanJson = await lanCall.json();
          trigger('flitsOtpGetLanguage', lanJson);
          window.flitsOtpLn = lanJson.flits.otp_login;
          window.flitsOtpIcons = lanJson.flits.icons;
        }
        else{
          let lanCallDefault = await fetch(`${window.flitsExtensionFeatures.cdnURL}/locales/default/en.json`);
          let lanCallDefaultJson = await lanCallDefault.json();
          trigger('flitsOtpGetLanguage', lanCallDefaultJson);
          window.flitsOtpLn = lanCallDefaultJson.flits.otp_login;
          window.flitsOtpIcons = lanCallDefaultJson.flits.icons;
        }
      } catch (error) {
        let lanCallDefault = await fetch(`${window.flitsExtensionFeatures.cdnURL}/locales/default/en.json`);
        let lanCallDefaultJson = await lanCallDefault.json();
        trigger('flitsOtpGetLanguage', lanCallDefaultJson);
        window.flitsOtpLn = lanCallDefaultJson.flits.otp_login;
        window.flitsOtpIcons = lanCallDefaultJson.flits.icons;
      }
    }
    else{
        let lanCallDefault = await fetch(`${window.flitsExtensionFeatures.cdnURL}/locales/default/en.json`);
        let lanCallDefaultJson = await lanCallDefault.json();
        trigger('flitsOtpGetLanguage', lanCallDefaultJson);
        window.flitsOtpLn = lanCallDefaultJson.flits.otp_login;
        window.flitsOtpIcons = lanCallDefaultJson.flits.icons;
    }
}