import React  from "react";
import OtpMain from "features/otp-login/otp-main";
import otpFeatures from "features/otp-login/components/services/flits/getFeatures";
let featuresInit = false;
function App() {
  const [otpLogin, setOtpLogin] = React.useState(false);
  function removeExtensionCode(){
    let oldLoader = document.querySelector('.flits-otp-loader-in-page');
    if(oldLoader){oldLoader.remove()};
    let mainContent = document.getElementById('MainContent') || document.getElementById('main') || document.getElementById('flits-otp-main-content');
    if(mainContent){
      let children = mainContent.children;
      for (let i = 0; i < children.length; i++) {
        children[i].classList.remove('flits-shopify-default-login-div');
      }
    }
  }
  async function flitsOtpLoginFeatures(){
    if(window.flitsExtensionObjects.customer && window.flitsExtensionObjects.customer == true){
      setOtpLogin(false);
    }
    else{
      let getFeatures = await otpFeatures.getOtpFeatures();
      if(getFeatures.status == true && getFeatures["features"]["settings.otp_login"] == true){
        window.flitsExtensionFeatures = getFeatures;
        setOtpLogin(true);
      }
      else{
        removeExtensionCode();
      }
    }
  }
  React.useEffect(() => {
    if(!featuresInit){
      featuresInit = true;
      flitsOtpLoginFeatures();
    }
  }, [featuresInit, flitsOtpLoginFeatures]);
  return (
    <>
    {(otpLogin == true) ?
      <OtpMain />
    : null}
    </>
  );
}

export default App;
