import React  from "react";
import './style.css';
import ButtonPrimary from "../../atoms/primary-button";
import InputField from "../../atoms/input";
import OTPInputV2 from "../../molecules/otp-input-v2";
import ActionButton from "../../atoms/action-button";
import otpSend from '../../services/otpApi/otpSend';
import otpVerfiy from '../../services/otpApi/otpVerify';
import otpRetry from "../../services/otpApi/otpRetry";
import SnackBar from '../../atoms/snackbar';
import { useSnackbar } from '../../atoms/snackbar/useSnackbar';
import CountryPhoneWrap from '../../molecules/country-phone-wrap';
import manageCustomer from "../../services/flits/manageCustomer";
import customerVerify from "../../services/flits/verifyCustomer";
import CountryDropdown from "../../atoms/country-dropdown";
import { doLogin } from "../../atoms/login/doLogin";
import Icon from "../../atoms/icons";
import { trigger } from "../../helper/event";

function AccountDetails({data}) {
	const [error, setError] = React.useState(null);
	const [emailError, setEmailError] = React.useState(null);
	const [phoneError, setPhoneError] = React.useState(null);
	const [fnameError, setFnameError] = React.useState(null);
	const [lnameError, setLnameError] = React.useState(null);
    const [fname, setFanme] = React.useState("");
    const [lname, setLanme] = React.useState("");
    const [email, setEmail] = React.useState(data.emailOrPhone == 'email' ? data.data : '');
    const [phone, setPhone] = React.useState(data.emailOrPhone == 'phone' ? data.phone : '');
    const [phoneDisabled, setPhoneDisabled] = React.useState(data.emailOrPhone == 'phone' ? true : false);
    const [emailDisabled, setEmailDisabled] = React.useState(data.emailOrPhone == 'email' ? true : false);
	const [emailNeedVerify, setEmailNeedVerify] = React.useState(false);
	const [phoneNeedVerify, setphoneNeedVerify] = React.useState(false);
	const [verifyOtpInput, setVerifyOtpInput] = React.useState("");
	const [verifyOtpSucess, setVerifyOtpSucess] = React.useState(false);
	const [sendOtpMsg, setSendOtpMsg] = React.useState(null);
	const [verifyOtpMsg, setVerifyOtpMsg] = React.useState(null);
	const [verifyOtpError, setVerifyOtpError] = React.useState(null);
	const { isActive, message, type, openSnackBar } = useSnackbar();
	const [country, setCountry] = React.useState(data.emailOrPhone == 'phone' ? data.country : '91');
	const [seconds, setSeconds] = React.useState(window.flitsGetOtpData.result.auth_data.resend_otp_after);
	const [isDisabled, setDisabled] = React.useState(true);
	const [verifyOtpSubmitText, setVerifyOtpSubmitText] = React.useState("Submit Email OTP");

	function phoneInputChange(e){
		const onlyDigits = e.target.value.replace(/\D/g, "");
		setPhone(onlyDigits);
		setPhoneError(null);
	}
	function isValidEmail(email) {
		const emailRegex = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
		return emailRegex.test(email);
	}
	function afterManageCustomerResponse(manageCustomerResponse){
		switch (manageCustomerResponse.action) {
			case window.flitsOtpCustomerAction.DO_LOGIN:
				doLogin(atob(manageCustomerResponse.result.w), atob(manageCustomerResponse.result.o));
				break;
			case  window.flitsOtpCustomerAction.ACTIVATE_ACCOUNT:
				window.location.href= manageCustomerResponse.result.account_activation_url;
			break;
			case window.flitsOtpCustomerAction.VERIFY_EMAIL:
				setEmailNeedVerify(true);
				setEmailError(window.flitsOtpLn.account_deatils.verify_email_error);
				break;
			case window.flitsOtpCustomerAction.VERIFY_PHONE:
				setphoneNeedVerify(true);
				setPhoneError(window.flitsOtpLn.account_deatils.verify_phone_error);
			break;
			case  window.flitsOtpCustomerAction.TAKE_OTHER_EMAIL:
			case  window.flitsOtpCustomerAction.TAKE_OTHER_PHONE:
				switch(manageCustomerResponse.message_code){
					case window.flitsOtpManageCustomerMsg.EMAIL_ALREADY_EXISTS:
						//openSnackBar(window.flitsOtpLn.global.errors.email_already_exists, 'alert');
						setEmailError(window.flitsOtpLn.global.errors.email_already_exists);
					break;
					case window.flitsOtpManageCustomerMsg.PHONE_ALREADY_EXISTS:
						//openSnackBar(window.flitsOtpLn.global.errors.phone_already_exists, 'alert');
						setPhoneError(window.flitsOtpLn.global.errors.phone_already_exists);
					break;
					case window.flitsOtpManageCustomerMsg.EMAIL_ALREADY_EXISTS_WITH_PHONE:
						let phone_exists_msg = window.flitsOtpLn.global.errors.email_already_exists_with_phone.replace('{{ phone }}', manageCustomerResponse.message_parameter.phone);
						//openSnackBar(phone_exists_msg, 'alert');
						setEmailError(phone_exists_msg);
					break;
					case window.flitsOtpManageCustomerMsg.PHONE_ALREADY_EXISTS_WITH_EMAIL:
						let email_exists_msg = window.flitsOtpLn.global.errors.phone_already_exists_with_email.replace('{{ email }}', manageCustomerResponse.message_parameter.email);
						//openSnackBar(email_exists_msg, 'alert');
						setPhoneError(email_exists_msg);
					break;
					case window.flitsOtpManageCustomerMsg.PROVIDE_VALID_EMAIL:
						setPhoneError(window.flitsOtpLn.global.errors.email_invalid);
					break;
					case window.flitsOtpManageCustomerMsg.PROVIDE_VALID_PHONE:
						setPhoneError(window.flitsOtpLn.global.errors.phone_required);
					break;
					case window.flitsOtpManageCustomerMsg.PHONE_LENGTH_NOT_PROPER:
						setPhoneError(window.flitsOtpLn.global.errors.phone_length_not_proper);
					break;
					case window.flitsOtpManageCustomerMsg.PHONE_MISSING_COUNTRY_CODE:
						setPhoneError(window.flitsOtpLn.global.errors.phone_missing_country_code);
					break;
					default:
						openSnackBar(window.flitsOtpLn.global.errors.something_wrong, 'alert');
					break;
				}
				document.getElementById('flits-account-detail-submit').removeAttribute('disabled');
			break;
			default:
				openSnackBar(window.flitsOtpLn.global.errors.something_wrong, 'alert');
				document.getElementById('flits-account-detail-submit').removeAttribute('disabled');
			break;
		}
	}
	async function emailPhoneOTPVerify(otpValue){
		if(otpValue == '' || otpValue.length != window.flitsGetOtpData.result.auth_data.otp_length){
			//openSnackBar(window.flitsOtpLn.global.errors.invalid_otp, 'alert');
			setVerifyOtpError(window.flitsOtpLn.global.errors.invalid_otp);
		}
		else{
			setVerifyOtpError(null);
			document.getElementById('flits-email-phone-verify-btn').setAttribute('disabled', 'true');
			let otpSubmitResponse = await otpVerfiy.verifyOtp(sendOtpMsg, otpValue);
			if(otpSubmitResponse.type == 'success'){
				openSnackBar(window.flitsOtpLn.global.errors.otp_verify_sucess, 'success');
				setVerifyOtpMsg(otpSubmitResponse.message);
				setVerifyOtpSucess(false);
				setEmailError(null);
				setPhoneError(null);
				let phoneInputPlus = `+${country+phone}`;
				let reCustomerVerifyIdentifier = (emailNeedVerify) ? email : phoneInputPlus;
				let reCustomerVerifyLoginWith = (emailNeedVerify) ? "email" : "phone";
				if(document.querySelector('.flits-otp-loader')){document.querySelector('.flits-otp-loader').classList.add('active')}
				let customerVerifyResponse = await customerVerify.verifyCustomer(reCustomerVerifyIdentifier, otpSubmitResponse.message);
				if(customerVerifyResponse.t){window.flitscustomerVerifyToken = customerVerifyResponse.t};
				switch (customerVerifyResponse.action) {
					case  window.flitsOtpCustomerAction.DO_LOGIN:
						doLogin(atob(customerVerifyResponse.result.w), atob(customerVerifyResponse.result.o));
					break;
					case  window.flitsOtpCustomerAction.CREATE_CUSTOMER:
					case  window.flitsOtpCustomerAction.TAKE_EMAIL:
					case  window.flitsOtpCustomerAction.TAKE_PHONE:
						let manageCustomerResponse = await manageCustomer.manageCustomer(reCustomerVerifyLoginWith,email,phoneInputPlus,fname,lname,window.flitscustomerVerifyToken);
						if(document.querySelector('.flits-otp-loader')){document.querySelector('.flits-otp-loader').classList.remove('active')}
						afterManageCustomerResponse(manageCustomerResponse);
					break;
					case  window.flitsOtpCustomerAction.ACTIVATE_ACCOUNT:
						window.location.href= customerVerifyResponse.result.account_activation_url;
					break;
					default:
						openSnackBar(window.flitsOtpLn.global.errors.something_wrong, 'alert');
						if(document.querySelector('.flits-otp-loader')){document.querySelector('.flits-otp-loader').classList.remove('active')}
					break;
				}
			}
			else{
				document.getElementById('flits-email-phone-verify-btn').removeAttribute('disabled');
				if(otpSubmitResponse.message){
					//openSnackBar(otpSubmitResponse.message, 'alert');
					setVerifyOtpError(otpSubmitResponse.message);
				}
				else{
					//openSnackBar(window.flitsOtpLn.global.errors.something_wrong, 'alert');
					setVerifyOtpError(window.flitsOtpLn.global.errors.something_wrong);
				}
			}
		}
	}
	function resolveAfter1Second() {
		return new Promise(resolve => {
		  setTimeout(() => {
			resolve('resolved');
		  }, 1000);
		});
	}
	async function emailPhoneVerify(e, data) {
		e.target.setAttribute('disabled', 'true');
		let validationDone = false;
		if(emailNeedVerify){
			if(email == ''){
				setEmailError(window.flitsOtpLn.global.errors.email_required);
				e.target.setAttribute('disabled', 'false');
			}
			else{
				if (!isValidEmail(email)) {
					setEmailError(window.flitsOtpLn.global.errors.email_invalid);
					e.target.setAttribute('disabled', 'false');
				} else {
					setEmailDisabled(true);
					setVerifyOtpSubmitText("Submit Email OTP");
					validationDone = true;
				}
			}
		}
		else{
			if(phone == '' || phone.length < 10){
				setPhoneError(window.flitsOtpLn.global.errors.phone_required);
				e.target.setAttribute('disabled', 'false');
			}
			else{
				setPhoneDisabled(true);
				setVerifyOtpSubmitText("Submit Phone OTP");
				validationDone = true;
			}
		}
		if(validationDone && data){
			let sendOtpResponse = await otpSend.sendOtp(data);
			if(sendOtpResponse.type == 'success'){
				openSnackBar(window.flitsOtpLn.global.errors.otp_sent_sucess, 'success');
				setVerifyOtpSucess(true);
				if(document.getElementById("flits-verify-otp-sucess")){
					document.getElementById("flits-verify-otp-sucess").scrollIntoView();
				}
				setSendOtpMsg(sendOtpResponse.message);
				let tempSecond = window.flitsGetOtpData.result.auth_data.resend_otp_after;
				while (tempSecond > 0) {
					let result = await resolveAfter1Second();
					setSeconds(prevSeconds => prevSeconds - 1);
					tempSecond--;
				}
				setSeconds(0);
				setDisabled(false);
			}
			else{
				if(sendOtpResponse.message){
					openSnackBar(sendOtpResponse.message, 'alert');
					e.target.removeAttribute('disabled');
				}
				else{
					e.target.removeAttribute('disabled');
					openSnackBar(window.flitsOtpLn.global.errors.something_wrong, 'alert');
				}
			}
		}
	}
	function showLoader(e){
		let button = e.target || e;
		let loader = '<div class="flits-loader flits-button-inside-loader" style="display: block;"></div>';
		let originalHTML = button.innerHTML;
    	button.innerHTML = loader;
		setTimeout(function() {
			if (button) {
				button.innerHTML = originalHTML;
			}
		}, 7000);
	}
	async function accountDetailsSubmit(e){
		e.target.setAttribute('disabled', 'true');
		let emailValid = false;
		let phoneValid = false;
		let fnameValid = false;
		let lnameValid = false;
		if(email == ''){
			setEmailError(window.flitsOtpLn.global.errors.email_required);
			e.target.removeAttribute('disabled');
		}
		else{
			if (!isValidEmail(email)) {
				setEmailError(window.flitsOtpLn.global.errors.email_invalid);
				e.target.removeAttribute('disabled');
			} else {
				setEmailError(null);
				emailValid = true;
			}
		}
		if(phone == '' || phone.length < 10){
			setPhoneError(window.flitsOtpLn.global.errors.phone_required);
			e.target.removeAttribute('disabled');
		}
		else{
			setPhoneError(null);
			phoneValid = true;
		}
		if(fname == ''){
			setFnameError(window.flitsOtpLn.global.errors.first_name_required);
			e.target.removeAttribute('disabled');
		}
		else{
			setFnameError(null);
			fnameValid = true;
		}
		if(lname == ''){
			setLnameError(window.flitsOtpLn.global.errors.last_name_required);
			e.target.removeAttribute('disabled');
		}
		else{
			setLnameError(null);
			lnameValid = true;
		}
		if(emailValid && phoneValid && fnameValid && lnameValid){
			let submitedData = {first_name: fname, last_name: fname, email: email, phone: country+phone};
			trigger('flitsAccountDetailSubmit', submitedData);
			showLoader(e);
			let phoneInputPlus = `+${country+phone}` ;
			if(document.querySelector('.flits-otp-loader')){document.querySelector('.flits-otp-loader').classList.add('active')}
			let manageCustomerResponse = await manageCustomer.manageCustomer(data.emailOrPhone, email, phoneInputPlus,fname, lname, window.flitscustomerVerifyToken);
			if(document.querySelector('.flits-otp-loader')){document.querySelector('.flits-otp-loader').classList.remove('active')}
			afterManageCustomerResponse(manageCustomerResponse);
		}
	}
	async function resendOTP(){
		setSeconds(window.flitsGetOtpData.result.auth_data.resend_otp_after);
		setDisabled(true);
		let retryOtpResponse = await otpRetry.retryOtp(sendOtpMsg);
		if(retryOtpResponse.type == 'success'){
			openSnackBar(retryOtpResponse.message, 'success');
		}
		else{
			if(retryOtpResponse.message){
				openSnackBar(retryOtpResponse.message, 'alert');
			}
			else{
				openSnackBar(window.flitsOtpLn.global.errors.something_wrong, 'alert');
			}
		}
	}
	function editEmailPhone(e){
		e.preventDefault();
		e.stopPropagation();
		setVerifyOtpSucess(false);
		document.querySelector('.flits-verify-btn').removeAttribute('disabled');
		if(emailNeedVerify){
			setEmailDisabled(false);
		}
		else{
			setPhoneDisabled(false);
		}
	}
	function handleOTPChange(value){
		setVerifyOtpError(null);
		setVerifyOtpInput(value);
		if (value.length == Number(window.flitsGetOtpData.result.auth_data.otp_length)) {
			setTimeout(() => {
				emailPhoneOTPVerify(value);
			}, 0);
		}
	}
	function formatTime(time){
		const minutess = Math.floor(time / 60).toString().padStart(2, '0');
  		const secondss = (time % 60).toString().padStart(2, '0');
  		return `${minutess}:${secondss}`;
	}

	return (
	<div className="flits-account-detail-box-wrapper flits-common-box-wrapper">
		<p className="flits-login-subhead flits-account-details-top-head">{window.flitsOtpLn.account_deatils.page_sub_head}</p>
		<h2 className="flits-login-header">{window.flitsOtpLn.account_deatils.page_title}</h2>
        <InputField
            type="text"
            placeholder={window.flitsOtpLn.global.placeholder.first_name}
            label={window.flitsOtpLn.global.label.first_name}
            name="fname"
            value={fname}
            onChange={(e) => {setFanme(e.target.value); setFnameError(null); }}
            maxlength="50"
        />
		{fnameError && <p className="flits-error">{fnameError}</p>}
        <InputField
            type="text"
            placeholder={window.flitsOtpLn.global.placeholder.last_name}
            label={window.flitsOtpLn.global.label.last_name}
            name="lname"
            value={lname}
            onChange={(e) => {setLanme(e.target.value); setLnameError(null);}}
            maxlength="50"
        />
		{lnameError && <p className="flits-error">{lnameError}</p>}
		<div className="flits-phone-email-wrap" rel={(phoneDisabled) ? 'phone' : 'email'}>
			<div className="flits-email-input-with-error">
				<div className={`flits-email-verify flits-verify-main-div ${emailError ? 'flits-email-has-error' : ''}`}>
					{emailDisabled ? <div className="flits-disabled-input-wrap">
						<label>{window.flitsOtpLn.global.label.email}</label>
						<div className="flits-read-only-text-input">{email}</div>
						</div>
						:
						<InputField
							type="email"
							placeholder={window.flitsOtpLn.global.placeholder.email}
							label={window.flitsOtpLn.global.label.email}
							name="email"
							value={email}
							onChange={(e) => {setEmail(e.target.value); setEmailError(null);}}
							isDisabled={emailDisabled}
						/>
					}
					{(emailNeedVerify) ? <button className="flits-verify-btn" onClick={(e) => emailPhoneVerify(e, email)}>{window.flitsOtpLn.account_deatils.verify_email_text}</button> : ''}
				</div>
				{emailError && <p className="flits-error">{emailError}</p>}
			</div>
			<div className="flits-phone-input-with-error">
				<div className={`flits-phone-verify flits-verify-main-div ${phoneError ? 'flits-phone-has-error' : ''}`}>
					<label>{window.flitsOtpLn.global.label.phone}</label>
					{(phoneDisabled) ? 
					<div className="flits-read-only-text-input">
						<CountryDropdown countryInput={country} onCountryChange={(e) => setCountry(e.target.value)} isDisabled={true} />
						<div className="flits-disable-phone-input">{phone}</div>
					</div>
					:
					<CountryPhoneWrap isDisabled={phoneDisabled} phoneInput={phone} onPhoneInputChange={phoneInputChange} countryInput={country} onCountryChange={(e) => setCountry(e.target.value)} />
					}
					{(phoneNeedVerify) ? <button className="flits-verify-btn" onClick={(e) => emailPhoneVerify(e, country+phone)}>{window.flitsOtpLn.account_deatils.verify_phone_text}</button> : ''}
				</div>
				{phoneError && <p className="flits-error">{phoneError}</p>}
			</div>
		</div>
		{(verifyOtpSucess) ? 
		<div className="flits-verify-otp-main" id="flits-verify-otp-sucess">
			<div className="flits-otp-sent-to">
				<p>{window.flitsOtpLn.account_deatils.otp_sent_to_text} {(phoneNeedVerify) ? phone : email}</p>
				<p onClick={editEmailPhone}><Icon type="edit_icon" /></p>
			</div>
			<p>{window.flitsOtpLn.account_deatils.enter_otp_text}</p>
			<OTPInputV2 maxLength={Number(window.flitsGetOtpData.result.auth_data.otp_length)} onOTPChange={handleOTPChange} />
			{verifyOtpError && <p className="flits-error">{verifyOtpError}</p>}
			<ActionButton value={verifyOtpSubmitText} onClick={() => emailPhoneOTPVerify(verifyOtpInput)} id="flits-email-phone-verify-btn" />
			<div className="flits-resend-otp">
				{(seconds > 0) ? <p>{window.flitsOtpLn.otp_verify.resend_otp_title} {formatTime(seconds)}</p> : <p className="flits-resend-otp-btn" onClick={resendOTP}>{window.flitsOtpLn.otp_verify.resend_otp_btn}</p>}
			</div>
		</div>
		: ''
		}
		{error && <p className="flits-error">{error}</p>}
		<ButtonPrimary
        value={window.flitsOtpLn.account_deatils.submit_btn}
        onClick={accountDetailsSubmit}
        icon={'continue_icon'}
		id="flits-account-detail-submit"
        />
		<SnackBar isActive ={isActive} message ={message} type={type}  />
	</div>
	);
}

export default AccountDetails;
