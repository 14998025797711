import React  from "react";
import './style.css';
import ButtonPrimary from "../../atoms/primary-button";
import Icon from "../../atoms/icons";
import { trigger } from "../../helper/event";
import OTPInputV2 from "../../molecules/otp-input-v2";

function OtpVerificationPage({data, backStep, otpSubmitMain, resendOtpMain}) {
	const [otpInput, setOtpInput] = React.useState("");
	const [otpInputDisabled, setotpInputDisabled] = React.useState(false);
	const [seconds, setSeconds] = React.useState(window.flitsGetOtpData.result.auth_data.resend_otp_after);
	const [error, setError] = React.useState(null);
	React.useEffect(() => {
		if (seconds > 0) {
		  setTimeout(() => setSeconds(seconds - 1), 1000);
		} else {
		  setSeconds(0);
		}
	});
	function showLoader(e){
		let button = e.target || e;
		let loader = '<div class="flits-loader flits-button-inside-loader" style="display: block;"></div>';
		let originalHTML = button.innerHTML;
    	button.innerHTML = loader;
    	button.disabled = true;
		setTimeout(function() {
			if (button) {
				button.innerHTML = originalHTML;
				button.disabled = false;
			}
		}, 7000);
	}
	function removeError(){
		let otpVerifyButton = document.getElementById('flits-otp-verify-submit-btn');
		let previousElement = otpVerifyButton.previousElementSibling;
		if (previousElement && previousElement.classList.contains('flits-static-error')) {
			previousElement.remove();
		}
	}
	function otpSubmit(e){
		removeError();
		setError(null);
		if(otpInput == '' || otpInput.length != Number(window.flitsGetOtpData.result.auth_data.otp_length)){
			setError(window.flitsOtpLn.global.errors.invalid_otp);
		}
		else{
			showLoader(e);
			trigger('flitsOtpVerifyClick', otpInput);
			otpSubmitMain(data, otpInput);
		}
	}
	function resendOTP(){
		setSeconds(window.flitsGetOtpData.result.auth_data.resend_otp_after);
		trigger('flitsOtpRetryClick', null);
		resendOtpMain();
	}
	// function onOtpKeyUP(e){
	// 	if(e.target.value.length == e.target.maxLength){
	// 		e.target.blur();
	// 		trigger('flitsOtpVerifyClick', otpInput);
	// 		otpSubmitMain(data, otpInput);
	// 		showLoader(document.getElementById('flits-otp-verify-submit-btn'));
	// 	}
	// }
	function handleOTPChange(value) {
		setError(null);
		removeError();
		setOtpInput(value);
		if (value.length == Number(window.flitsGetOtpData.result.auth_data.otp_length)) {
			setTimeout(() => {
				let otpVerifyButton = document.getElementById('flits-otp-verify-submit-btn');
				//removeError();
				showLoader(otpVerifyButton);
				otpSubmitMain(data, value);
				trigger('flitsOtpVerifyClick', value);
			}, 0);
		}
	}
	function formatTime(time){
		const minutess = Math.floor(time / 60).toString().padStart(2, '0');
  		const secondss = (time % 60).toString().padStart(2, '0');
  		return `${minutess}:${secondss}`;
	}
	
	return (
	<div className="flits-otp-box-wrapper flits-common-box-wrapper">
		<h2 className="flits-login-header">{window.flitsOtpLn.otp_verify.page_title}</h2>
        <p className="flits-login-description">{window.flitsOtpLn.otp_verify.page_sub_head} {(data.emailOrPhone == 'phone') ? 'Phone Number' : 'Email Address'}</p>
		<div className="flits-edit-email-phone" onClick={backStep}>{(data.emailOrPhone == 'phone') ? `+${data.data}` : data.data} <Icon type="edit_icon" /></div>
		<OTPInputV2 maxLength={Number(window.flitsGetOtpData.result.auth_data.otp_length)} onOTPChange={handleOTPChange} isDisabled={otpInputDisabled} />
		{error && <p className="flits-error">{error}</p>}
		<ButtonPrimary
        value={window.flitsOtpLn.otp_verify.next_step_btn}
        onClick={otpSubmit}
        icon={'continue_icon'}
		id="flits-otp-verify-submit-btn"
        />
		<div className="flits-resend-otp">
			{/* <ActionButton
				value={window.flitsOtpLn.otp_verify.resend_otp_btn}
				onClick={resendOTP}
				isDisabled={isDisabled}
			/> */}
			{(seconds > 0) ? <p>{window.flitsOtpLn.otp_verify.resend_otp_title} {formatTime(seconds)}</p> : <p className="flits-resend-otp-btn" onClick={resendOTP}>{window.flitsOtpLn.otp_verify.resend_otp_btn}</p>}
		</div>
	</div>
	);
}

export default OtpVerificationPage;
