import React from "react";
import "./style.css";

function SnackBar({message, isActive, type}) {
    return(
        <div className = {isActive ? ['flits-otp-snackbar', 'show', type].join(" ") : 'flits-otp-snackbar'}>
            {message}
        </div>
    )
}

export default SnackBar;