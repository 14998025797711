import React, { useState, useRef } from 'react';
import "./style.css";

function OTPInputV2({ maxLength, onOTPChange, isDisabled }) {
  const [otp, setOTP] = useState(Array(maxLength).fill(''));
  const inputRefs = useRef([]);

  const handleInputPaste = (e) => {
    e.preventDefault(); // Prevent the default paste behavior
  
    // Get the pasted text from the clipboard
    const pastedText = e.clipboardData.getData('text');
    const pastedOTP = pastedText.slice(0, maxLength);
  
    // Clear the existing OTP and set the new one with a delay
    setOTP(Array(maxLength).fill(''));
    setTimeout(() => {
      requestAnimationFrame(() => {
        const newOTP = [...pastedOTP.split(''), ...Array(maxLength - pastedOTP.length).fill('')];
        setOTP(newOTP);
  
        // Move focus to the next input
        if (inputRefs.current[maxLength - 1]) {
          inputRefs.current[maxLength - 1].focus();
        }
  
        const enteredOTP = newOTP.join('');
        onOTPChange(enteredOTP); // Invoke the callback function
      });
    }, 0);
  };
  

  const handleInputKeyDown = (index, e) => {
    if (e.key === 'Backspace' && otp[index] === '' && index > 0) {
      // Move focus to the previous input on Backspace press
      inputRefs.current[index - 1].focus();
    }
  };

  const handleInput = (index, e) => {
    const numericValue = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
    const newOTP = [...otp];
    newOTP[index] = numericValue.slice(0, 1); // Update the OTP array with the first character
    setOTP(newOTP);
  
    // Move focus to the next input
    if (numericValue !== '' && index < maxLength - 1) {
      inputRefs.current[index + 1].focus();
    }
  
    const enteredOTP = newOTP.join('');
    onOTPChange(enteredOTP); // Invoke the callback function
  };  

  return (
    <div className='flits-otp-input-wrapper-new'>
      {[...Array(maxLength)].map((_, index) => (
        <input
          key={index}
          type="text"
          maxLength={1}
          value={otp[index]}
          onChange={(e) => handleInput(index, e)}
          onPaste={handleInputPaste}
          onKeyDown={(e) => handleInputKeyDown(index, e)}
          ref={(ref) => (inputRefs.current[index] = ref)}
          disabled={isDisabled}
        />
      ))}
    </div>
  );
}

export default OTPInputV2;