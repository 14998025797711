import { trigger } from "../../helper/event";
class OtpVerfiy {
    verifyOtp = async (sendOtpMsg, otpInput) => {
		switch(window.flitsGetOtpData.result.otp_driver){
			case "Msg91":
				return new Promise((resolve, reject) => {
					window.verifyOtp(otpInput, (data) => {
						trigger('flitsOtpVerifyResponse', data);
						let returnjson = data;
						if(returnjson) resolve(returnjson);
					}, (error) => {
						trigger('flitsOtpVerifyResponse', error);
						let returnjson = error;
						resolve(returnjson);
					});
				});
			default:
				let noService = {"message": "No OTP Service Provider", "type": "error"}
                return noService;
		}
    }
}
const otpVerfiy = new OtpVerfiy();
export default otpVerfiy;