import { trigger } from "../../helper/event";
class OtpFeatures {
    getOtpFeatures = async () => {
        let requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        let result = await fetch(`${window.location.origin}${window.flitsExtensionObjects.base_url}/features?token=${window.flitsExtensionObjects.token}`, requestOptions)
        let resultJson = await result.json();
        trigger('flitsOtpGetFeatures', resultJson);
        return resultJson;
    }
}
const otpFeatures = new OtpFeatures();
export default otpFeatures;