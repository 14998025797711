import React  from "react";
import "./style.css";
import CountryDropdown from "../../atoms/country-dropdown";

const CountryPhoneWrap = ({ isDisabled, phoneInput, onPhoneInputChange, countryInput, onCountryChange, handleKeyPress }) => {
    return (
      <div className="flits-input-wrap flits-country-dropdown-wrap">
        <input type="tel" placeholder={window.flitsOtpLn.global.placeholder.phone} onKeyDown={handleKeyPress} className="flits-input" name="phone" value={phoneInput} onChange={onPhoneInputChange} maxLength="10" disabled={isDisabled}></input>
        <CountryDropdown countryInput={countryInput} onCountryChange={onCountryChange} />
      </div>
    );
};
  
export default CountryPhoneWrap;