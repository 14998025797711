import React  from "react";
import './style.css';
import CountryPhoneWrap from '../../molecules/country-phone-wrap';
import ButtonPrimary from '../../atoms/primary-button';
import ActionButton from "../../atoms/action-button";
import InputField from "../../atoms/input";
import { trigger } from "../../helper/event";

function LoginPage({onLoginSubmit, onBackStep}) {
  const [otpInputType, setOtpInputType] = React.useState(onBackStep.emailOrPhone ? onBackStep.emailOrPhone : 'phone');
  const [email, setEmail] = React.useState('');
  const [error, setError] = React.useState(null);
  const [phone, setPhone] = React.useState('');
  const [country, setCountry] = React.useState('91');
  const [emailOtpHint, setEmailOtpHint] = React.useState(window.flitsOtpLn.login.otp_hint_email.replace(/6/g, window.flitsGetOtpData.result.auth_data.otp_length));
  const [phoneOtpHint, setPhoneOtpHint] = React.useState(window.flitsOtpLn.login.otp_hint_phone.replace(/6/g, window.flitsGetOtpData.result.auth_data.otp_length));

  function phoneInputChange(e){
    setError(null);
    const onlyDigits = e.target.value.replace(/\D/g, "");
    setPhone(onlyDigits);
  }
  function emailClick() {
    setOtpInputType('email');
    setError(null);
  }
  function phoneClick() {
    setOtpInputType('phone');
    setError(null);
  }
  // function isValidEmail(email) {
  //   return /\S+@\S+\.\S+/.test(email);
  // }
  function isValidEmail(email) {
    const emailRegex = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
    return emailRegex.test(email);
  }
  function showLoader(e) {
    let button = e.target;
    let loader = '<div class="flits-loader flits-button-inside-loader" style="display: block;"></div>';
    let originalHTML = button.innerHTML;
    button.innerHTML = loader;
    button.disabled = true;
  
    setTimeout(function() {
      if (button) {
        button.innerHTML = originalHTML;
        button.disabled = false;
      }
    }, 3000);
  }  
  function submitForm(e) {
    e.preventDefault();
		e.stopPropagation();
    if(otpInputType == 'email'){
      if(email == ''){
        setError(window.flitsOtpLn.global.errors.email_required);
      }
      else{
        if (!isValidEmail(email)) {
          setError(window.flitsOtpLn.global.errors.email_invalid);
        } else {
          setError(null);
          let submitData = {
            emailOrPhone : 'email',
            data: email
          };
          showLoader(e);
          trigger('flitsOtpSendClick', submitData);
          onLoginSubmit(submitData);
        }
      }
    }
    else{
      if(phone == '' || phone.length != 10){
        setError(window.flitsOtpLn.global.errors.phone_required);
      }
      else{
        let submitData = {
          emailOrPhone : 'phone',
          data: country+phone,
          phone: phone,
          country: country
        };
        showLoader(e);
        trigger('flitsOtpSendClick', submitData);
        onLoginSubmit(submitData);
      }
    }
  }
  function inputEnterClick(e){
    if (e.keyCode === 13) {
      submitForm(e);
    }
  }

  return (
    <div className='flits-login-box-wrapper flits-common-box-wrapper'>
        <h2 className="flits-login-header">{window.flitsOtpLn.login.page_title}</h2>
        <p className="flits-login-description">{(otpInputType == 'phone') ? window.flitsOtpLn.login.page_sub_head_phone : window.flitsOtpLn.login.page_sub_head_email }</p>
        {(otpInputType == 'phone') ?
          <CountryPhoneWrap phoneInput={phone} handleKeyPress={inputEnterClick} onPhoneInputChange={phoneInputChange} countryInput={country} onCountryChange={(e) => setCountry(e.target.value)} />
        : 
        <InputField
          type="email"
          placeholder={window.flitsOtpLn.global.placeholder.email}
          label=""
          name="email"
          value={email}
          onChange={(e) => {setEmail(e.target.value); setError(null);}}
          handleKeyPress={inputEnterClick}
         />
        }
        {error && <p className="flits-error">{error}</p>}
        <ButtonPrimary
        value={window.flitsOtpLn.login.continue}
        onClick={submitForm}
        icon={'continue_icon'}
        />
        <div className="flits-otp-hint-text">{(otpInputType == 'phone') ? phoneOtpHint : emailOtpHint }</div>
        <div className="flits-otp-or-text">
          <span>Or {window.flitsOtpLn.login.or_login_using}</span>
        </div>
        {(otpInputType == 'phone') ?
        <ActionButton
          value={window.flitsOtpLn.login.change_login_btn_email}
          onClick={emailClick}
          icon={'email_icon'}
        />
        :
        <ActionButton
          value={window.flitsOtpLn.login.change_login_btn_phone}
          onClick={phoneClick}
          icon={'phone_icon'}
        />
        }
    </div>
  );
}

export default LoginPage;
