export function loadStyleScript(url) {
    let isCss = url.indexOf('.css') != -1;
    let tag = isCss ? document.createElement("link") : document.createElement("script");
    tag.type = isCss ? "text/css" : "text/javascript";
    isCss && (tag.rel = "stylesheet");
    isCss && (tag.href = url);
    !isCss && (tag.src = url);
    document.getElementsByTagName("head")[0].appendChild(tag);
    return new Promise((res, rej) => {
        tag.onload = function() {
          res();
        }
        tag.onerror = function () {
          rej();
        }
    });
}