import { trigger } from "../../helper/event";
class OtpSend {
    sendOtp = async (submitedValue) => {
        switch(window.flitsGetOtpData.result.otp_driver){
            case "Msg91":
                return new Promise((resolve, reject) => {
                    window.sendOtp(submitedValue, (data) => {
                        trigger('flitsOtpSendResponse', data);
                        let returnjson = data;
                        if(returnjson) resolve(returnjson);
                    }, (error) => {
                        trigger('flitsOtpSendResponse', error);
                        let returnjson = error;
                        resolve(returnjson);
                    });
                });
            default:
                let noService = {"message": "No OTP Service Provider", "type": "error"}
                return noService;
        }
    }
}
const otpSend = new OtpSend();
export default otpSend;