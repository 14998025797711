import React  from "react";
import Icon from "../icons";
import "./style.css";

const ActionButton = ({ value, icon, onClick, isDisabled, id }) => (
    <button
        className="flits-action-button"
        onClick={onClick}
        disabled={isDisabled}
        id={id}
    >
        {(icon) ? <Icon type={icon} /> : null } {value} 
    </button>
  );
  
export default ActionButton;