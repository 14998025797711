import React  from "react";
import Icon from "../icons";
import "./style.css";

const ButtonPrimary = ({ value, icon, onClick, id }) => (
      <button
        className="flits-primary-button flits-otp-primary-btn"
        onClick={onClick}
        id={id}
      >
       {value} {(icon) ? <Icon type={icon} /> : null } <div className="flits-loader flits-button-inside-loader"></div>
      </button>
  );
  
export default ButtonPrimary;