import React  from "react";
import Layout from "./components/templates";
import Loader from "./components/atoms/loader";
import otpSettings from "./components/services/flits/getSettings";
import { getLanguage } from "./components/services/flits/getLanguage";
import { loadStyleScript } from "./components/atoms/load-style-script/loadStyleScript";
import './otp-main.css';
let didInit = false;
function OtpMain() {
  const [layoutStyle, setlayoutStyle] = React.useState('default');
  const [data, setData] = React.useState(null);
  let all_login = document.querySelectorAll("a[href='/account/login']"); // Change selector as per you reqirement.
  for (let i = 0; i < all_login.length; i++) {
    let al = all_login[i];
    al.setAttribute("href", "#flits-otp-login-root-container");
    al.setAttribute('onclick','window.otpOpen(event)');
  }
  function ifLoginPageAfterApi(){
    setlayoutStyle('default');
    let oldLoader = document.querySelector('.flits-otp-loader-in-page');
    if(oldLoader){oldLoader.remove()};
    let mainContent = document.getElementById('MainContent') || document.getElementById('main') || document.getElementById('flits-otp-main-content');
    let flitsOTPContainer = document.querySelector(".flits-otp-login-root-container");
    //let flitsOTPContainer = document.querySelector(".flits-otp-login-root");
    mainContent.appendChild(flitsOTPContainer);
    document.querySelector("#flits-otp-login-root-container").classList.add("active");
}
  window.otpOpen = function (event){
    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();
    if(layoutStyle == 'default'){
      window.location.href = '/account/login';
    }
    else{
      document.querySelector("#flits-otp-login-root-container").classList.add("active");
      document.querySelector('body').classList.add("flits-otp-login-open");
    }
  }
  // window.addEventListener('click', function(e){
  //   if(layoutStyle != 'default'){
  //     let container = document.querySelector('#flits-otp-login-root-container .flits-otp-app > div');
  //     if (!container.contains(e.target) && e.target.tagName.toLowerCase() !== 'img' && e.target.tagName.toLowerCase() !== 'svg') {
  //       // Clicked outside the box and not on an image, close the popup
  //       document.querySelector("#flits-otp-login-root-container").classList.add("close-animation");
  //       setTimeout(() => {
  //         document.querySelector("#flits-otp-login-root-container").classList.remove("active");
  //         document.querySelector("#flits-otp-login-root-container").classList.remove("close-animation");
  //       }, 250);
  //     }
  //   }
  // });
  async function getData(){
    let initialData = await otpSettings.getOtpSettings();
    if(initialData.status == true){
        await getLanguage();
        window.flitsOtpConfiguration = {
          widgetId: initialData?.result.auth_data.widget_id,
          tokenAuth: initialData?.result.auth_data.token_auth,
          exposeMethods: true,
          pluginsource: 79,
          success: (data) =>{},
          failure: (error) =>{}
        };
        loadStyleScript('https://control.msg91.com/app/assets/otp-provider/otp-provider.js', '').then(() => {
          let FlitsMsg91Loaded = new CustomEvent('FlitsMsg91Loaded');
          window.dispatchEvent(FlitsMsg91Loaded);
          setData(initialData);
        })
        .catch(() => {
          window.location.reload();
        });
        window.flitsGetOtpData = initialData;
        if(window.location.pathname == '/account/login' || window.location.pathname == '/account/register'){
            ifLoginPageAfterApi();
        }
        else{
            setlayoutStyle(initialData.result.auth_data.login_form_type);
        }
        let rootParent =  document.querySelector('.flits-otp-login-root');
        if (typeof(rootParent) != 'undefined' && rootParent != null){
            rootParent.classList.add(`flits-theme-${window.Shopify.theme.id}`);
        }
        window.flitsOtpCustomerAction = {
            CREATE_CUSTOMER: 1,
            DO_LOGIN: 2,
            TAKE_EMAIL: 3,
            TAKE_PHONE: 4,
            ACTIVATE_ACCOUNT: 5,
            VERIFY_EMAIL: 6,
            VERIFY_PHONE: 7,
            TAKE_OTHER_EMAIL: 8,
            TAKE_OTHER_PHONE: 9
        }
        window.flitsOtpManageCustomerMsg = {
          EMAIL_ALREADY_EXISTS : 1,
          PHONE_ALREADY_EXISTS : 2,
          EMAIL_ALREADY_EXISTS_WITH_PHONE : 3,
          PHONE_ALREADY_EXISTS_WITH_EMAIL : 4,
          SOMETHING_WENT_WRONG : 5,
          PROVIDE_VALID_EMAIL : 6,
          PROVIDE_VALID_PHONE : 7,
          PHONE_LENGTH_NOT_PROPER : 8,
          PHONE_MISSING_COUNTRY_CODE : 9
        }
        if(initialData.result.auth_data.custom){
          if(initialData.result.auth_data.custom.css.full){
            loadStyleScript(initialData.result.auth_data.custom.css.full)
          }
          if(initialData.result.auth_data.custom.css.extra){
            loadStyleScript(initialData.result.auth_data.custom.css.extra)
          }
          if(initialData.result.auth_data.custom.js.full){
            loadStyleScript(initialData.result.auth_data.custom.js.full)
          }
          if(initialData.result.auth_data.custom.js.extra){
            loadStyleScript(initialData.result.auth_data.custom.js.extra)
          }
        }
    }
    else{
      if(window.location.pathname == '/account/login' || window.location.pathname == '/account/register'){
        document.querySelector("#flits-otp-login-root-container").classList.remove("active");
        let oldLoader = document.querySelector('.flits-otp-loader-in-page');
        if(oldLoader){oldLoader.remove()};
        let mainContent = document.getElementById('MainContent') || document.getElementById('main') || document.getElementById('flits-otp-main-content');
        if(mainContent){
          let children = mainContent.children;
          for (let i = 0; i < children.length; i++) {
            children[i].classList.remove('flits-shopify-default-login-div');
          }
        }
      }
    }
  }
  React.useEffect(() => {
    if(!didInit){
      didInit = true;
      getData();
    }
  }, [didInit, getData]);
  return (
    <div className="flits-otp-app" rel={layoutStyle}>
    {(data) ?
      <Layout appData{...data} />
    : <Loader active={true}/>}
     </div>
  );
}
export default OtpMain;