import React  from "react";
import "./style.css";
import jsonData from './country.json';
import { setFlagPosition } from "./setFlagPosition";

const CountryDropdown = ({ countryInput, onCountryChange, isDisabled }) => {
  const [selectedOption, setSelectedOption] = React.useState(countryInput);

  function handleChange(event) {
    let index = event.target.selectedIndex;
    let optionElement = event.target.childNodes[index];
    let option =  optionElement.getAttribute('country-code');
    let flagPos = setFlagPosition(option);
    let xPos = flagPos.x + 'px ';
    let yPos = flagPos.y + 'px';
    document.getElementById("flitsCountryFlag").style.backgroundPosition = [xPos, yPos].join('');
    setSelectedOption(event.target.value);
    onCountryChange(event);
  };
  let availableCountryArray = ['IN']
  let filterdData = jsonData.filter(item => availableCountryArray.includes(item.code));
  
    return (
        <div className="flits-country-drpdown">
          <span className="flits-country-flag" id="flitsCountryFlag"></span>
          <div className="flits-select-row">
            <select value={selectedOption} onChange={handleChange} disabled={isDisabled}>
            {filterdData.map((e, i) => 
              <option key={i}  value={e.dial_code} country-code={e.code} >{e.name} ({e.dial_code})</option>
            )}
            </select>
            <div className="flits-select-arrow">
              <svg viewBox="0 0 24 24" width="14" height="14" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" fill="none" shapeRendering="geometricPrecision">
                <path d="M6 9l6 6 6-6"></path>
              </svg> 
            </div>
          </div>
        </div>
    );
};
  
export default CountryDropdown;