export function setFlagPosition(iso) {
    let scaleSize = 1.25;
    let flagSvgSize = {
        "w": 645 * scaleSize,
        "h": 515 * scaleSize
    };
    let displayFlagSize = {
        "w": 20 * scaleSize,
        "h": 15 * scaleSize
    };
    let gap = {
        x: 5 * scaleSize,
        y: 5 * scaleSize
    };
    let posFlagSize = {
        "w": displayFlagSize.w + gap.x,
        "h": displayFlagSize.h + gap.y
    };
    function calcPos(letter, size) {
        return -(letter.toLowerCase().charCodeAt(0) - 97) * size
    }
    var x = calcPos(iso[1], posFlagSize.w);
    var y = calcPos(iso[0], posFlagSize.h);
    return {
      "x": x,
      "y": y
    }
}