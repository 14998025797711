import { trigger } from "../../helper/event";
class OtpRetry {
    retryOtp = async (sendOtpMsg, submitedValue) => {
        let numoremail = /^\+?\d+$/.test(submitedValue);
        let channel = (numoremail) ? '11' : '3';
        switch(window.flitsGetOtpData.result.otp_driver){
            case "Msg91":
                return new Promise((resolve, reject) => {
                    window.retryOtp(channel, (data) => {
                        trigger('flitsOtpRetryResponse', data);
                        let returnjson = data;
                        if(returnjson) resolve(returnjson);
                    }, (error) => {
                        trigger('flitsOtpRetryResponse', error);
                        let returnjson = error;
                        resolve(returnjson);
                    });
                });
            default:
                let noService = {"message": "No OTP Service Provider", "type": "error"}
                return noService;
        }
    }
}
const otpRetry = new OtpRetry();
export default otpRetry;