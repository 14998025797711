import React  from "react";
import "./style.css";

const InputField = ({ value, label, name, placeholder, type, onChange, maxlength, isDisabled, handleKeyPress }) => (
    <div className="flits-form-group">
      {label && <label htmlFor="input-field">{label}</label>}
      <input
        type={type}
        value={value}
        name={name}
        className="flits-input"
        placeholder={placeholder}
        onChange={onChange}
        maxLength={maxlength}
        disabled={isDisabled}
        onKeyDown={handleKeyPress}
      />
    </div>
  );
  
export default InputField;