import { trigger } from "../../helper/event";
class ManageCustomer {
    manageCustomer = async (loginwith, email, phone,fname, lname, verify_access_token) => {
        var formdata = new FormData();
        formdata.append("token", window.flitsExtensionObjects.token);
        formdata.append("login_with", loginwith);
        formdata.append("email", email);
        formdata.append("phone", phone);
        formdata.append("first_name", fname);
        formdata.append("last_name", lname);
        formdata.append("t", verify_access_token);

        var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
        };
        let result = await fetch(`${window.location.origin}${window.flitsExtensionObjects.base_url}/v1/otp-login/set-customer`, requestOptions);
        let resultJson = await result.json();
        trigger('flitsOtpManageCustomer', resultJson);
        return resultJson;
    }
}
const manageCustomer = new ManageCustomer();
export default manageCustomer;