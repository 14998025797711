import { trigger } from "../../helper/event";
class CustomerVerify {
    verifyCustomer = async (otpInput, verify_access_token) => {
        var formdata = new FormData();
        formdata.append("token", window.flitsExtensionObjects.token);
        formdata.append("identifier", otpInput);
        formdata.append("otp_reference", verify_access_token);

        var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
        };

        let result = await fetch(`${window.location.origin}${window.flitsExtensionObjects.base_url}/v1/otp-login/verify-customer`, requestOptions);
        let resultJson = await result.json();
        trigger('flitsOtpVerifyCustomer', resultJson);
        return resultJson;
    }
}
const customerVerify = new CustomerVerify();
export default customerVerify;