import { useState, useEffect } from 'react';
import './style.css';
import LoginPage from '../pages/login-page';
import OtpVerificationPage from '../pages/otp-verify-page';
import AccountDetails from '../pages/account-details';
import SnackBar from '../atoms/snackbar';
import { useSnackbar } from '../atoms/snackbar/useSnackbar';
import otpSend from '../services/otpApi/otpSend';
import otpRetry from '../services/otpApi/otpRetry';
import otpVerfiy from '../services/otpApi/otpVerify';
import customerVerify from '../services/flits/verifyCustomer';
import { doLogin } from '../atoms/login/doLogin';
import Icon from '../atoms/icons';
import Loader from '../atoms/loader';

function Layout(appData) {
	const [step, setStep] = useState('1');
	const [firstData, setFirstData] = useState({});
	const [sendOtpMsg, setSendOtpMsg] = useState(null);
	const [verifyOtpMsg, setVerifyOtpMsg] = useState(null);
	const { isActive, message, type, openSnackBar } = useSnackbar();
	const [loaderActive, setLoaderActive] = useState(false);

  async function onLoginSubmit(data){
    if(data){
		setFirstData(data);
		let submitedValue = data.data;
		let sendOtpResponse = await otpSend.sendOtp(submitedValue);
		if(sendOtpResponse.type == 'success'){
			openSnackBar(window.flitsOtpLn.global.errors.otp_sent_sucess, 'success');
			setSendOtpMsg(sendOtpResponse.message);
			setStep(2);
		}
		else{
			if(sendOtpResponse.code == '403'){
				openSnackBar(window.flitsOtpLn.global.errors.ip_block_error, 'alert');
			}
			else{
				if(sendOtpResponse.message){
					openSnackBar(sendOtpResponse.message, 'alert');
				}
				else{
					openSnackBar(window.flitsOtpLn.global.errors.something_wrong, 'alert');
				}	
			}
		}
			
    }
  }
  function backStep(){
    setStep(1);
  }
  async function otpSubmitMain(data, otpInput){
    setFirstData(data);
		let otpSubmitResponse = await otpVerfiy.verifyOtp(sendOtpMsg, otpInput);
		if(otpSubmitResponse.type == 'success'){
			//openSnackBar(window.flitsOtpLn.global.errors.otp_verify_sucess, 'success');
			setVerifyOtpMsg(otpSubmitResponse.message);
			let otpVerifyReference = otpSubmitResponse.message;
			let customOtpInput;
			if(firstData.emailOrPhone == 'phone'){
				customOtpInput = `+${data.data}`;
			}
			else{
				customOtpInput = data.data;
			}
			setLoaderActive(true);
			let customerVerifyResponse = await customerVerify.verifyCustomer(customOtpInput, otpVerifyReference);
			setLoaderActive(false);
			if(customerVerifyResponse.t){window.flitscustomerVerifyToken = customerVerifyResponse.t};
			switch (customerVerifyResponse.action) {
				case  window.flitsOtpCustomerAction.CREATE_CUSTOMER:
					setStep(3);
				break;
				case  window.flitsOtpCustomerAction.DO_LOGIN:
					doLogin(atob(customerVerifyResponse.result.w), atob(customerVerifyResponse.result.o));
				break;
				case  window.flitsOtpCustomerAction.TAKE_EMAIL:
					setStep(3);
				break;
				case  window.flitsOtpCustomerAction.TAKE_PHONE:
					setStep(3);
				break;
				case  window.flitsOtpCustomerAction.ACTIVATE_ACCOUNT:
					window.location.href= customerVerifyResponse.result.account_activation_url;
				break;
				default:
					//openSnackBar(window.flitsOtpLn.global.errors.something_wrong, 'alert');
					let verifyOtpBtn = document.getElementById('flits-otp-verify-submit-btn');
					let verifyOtpErrorHtml = `<p class="flits-error flits-static-error">${window.flitsOtpLn.global.errors.something_wrong}</p>`;
					if(verifyOtpBtn){
						verifyOtpBtn.insertAdjacentHTML('beforebegin', verifyOtpErrorHtml);
					}
				break;
			}
		}
		else{
			let verifyOtpBtn = document.getElementById('flits-otp-verify-submit-btn');
			let verifyOtpErrorHtml;
			verifyOtpBtn.removeAttribute('disabled');
			if(otpSubmitResponse.message){
				//openSnackBar(otpSubmitResponse.message, 'alert');
				verifyOtpErrorHtml = `<p class="flits-error flits-static-error">${otpSubmitResponse.message}</p>`;
			}
			else{
				//openSnackBar(window.flitsOtpLn.global.errors.something_wrong, 'alert');
				verifyOtpErrorHtml = `<p class="flits-error flits-static-error">${window.flitsOtpLn.global.errors.something_wrong}</p>`;
			}
			verifyOtpBtn.insertAdjacentHTML('beforebegin', verifyOtpErrorHtml);
		}
  }
  async function resendOtpMain(){
		let submitedValue = firstData?.data
		let retryOtpResponse = await otpRetry.retryOtp(sendOtpMsg, submitedValue);
		if(retryOtpResponse.type == 'success'){
			//openSnackBar(retryOtpResponse.message);
			openSnackBar(window.flitsOtpLn.otp_verify.resend_otp_sucess_msg, 'success');
		}
		else{
			if(retryOtpResponse.message){
				openSnackBar(retryOtpResponse.message, 'alert');
			}
			else{
				openSnackBar(window.flitsOtpLn.global.errors.something_wrong, 'alert');
			}
		}
  }
  function closeClick(){
	document.querySelector("#flits-otp-login-root-container").classList.add("close-animation");
	setTimeout(() => {
		document.querySelector("#flits-otp-login-root-container").classList.remove("active");
		document.querySelector("#flits-otp-login-root-container").classList.remove("close-animation");
		document.querySelector("body").classList.remove("flits-otp-login-open");
	}, 250);
  }
  return (
    <div>
		{(appData.result.auth_data.login_form_type != 'default') ?
		<div className='flits-otp-close-btn' onClick={closeClick}>
			<Icon type="close_icon" />
		</div>
		:''}
        <div className='flits-main-otp-wrapper'>
          {(step == 1) ? <LoginPage onLoginSubmit={onLoginSubmit} onBackStep={firstData} />: '' }
          {(step == 2) ? <OtpVerificationPage data={firstData} backStep={backStep} otpSubmitMain={otpSubmitMain} resendOtpMain={resendOtpMain} /> : '' }
          {(step == 3) ? <AccountDetails data={firstData} /> : ''}
		  <SnackBar isActive ={isActive} message ={message} type={type}  />
		  <Loader active={loaderActive} />
        </div>
    </div>
  );
}

export default Layout;
