export function doLogin(email, password) {
    let login_form = document.createElement('form');
    login_form.setAttribute('action', '/account/login');
    login_form.setAttribute('method', 'post');
    login_form.setAttribute('autocomplete', 'off');
    let flitsLocal = localStorage.getItem("flits");
    let flitsLocalParsed = JSON.parse(flitsLocal);
    let redirectValue = window.location.href;
    if(flitsLocalParsed.flits_before_login_url){
        redirectValue = flitsLocalParsed.flits_before_login_url;
    }
    else if(window.location.pathname == '/account/login' || window.location.pathname == '/account/register'){
        redirectValue = '/account';
    }
    let formInnerHtml = `<input type="hidden" name="form_type" value="customer_login">
    <input type="email" name="customer[email]" class="flits-d-none" autocomplete="off" value="${email}">
    <input type="hidden" name="checkout_url"  class="flits-d-none" autocomplete="off" value="${redirectValue}">
    <input type="password" name="customer[password]"  class="flits-d-none" autocomplete="off" value="${password}">`;
    login_form.innerHTML = formInnerHtml;
    document.body.appendChild(login_form);
    login_form.submit();
}