import { trigger } from "../../helper/event";
class OtpSettings {
    getOtpSettings = async () => {
        let requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        let result = await fetch(`${window.location.origin}${window.flitsExtensionObjects.base_url}/v1/otp-login?token=${window.flitsExtensionObjects.token}`, requestOptions)
        let resultJson = await result.json();
        trigger('flitsOtpGetSettings', resultJson);
        return resultJson;
    }
}
const otpSettings = new OtpSettings();
export default otpSettings;